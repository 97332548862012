import { BroadcastComposerOutgoingMessageFetchResults } from '../../../../types/campaigns'
import { OutgoingMessageInterface } from '../../../../types/messages'
import { OutgoingMessage } from '../../../models'

export async function getMemberCountForCampaign(id) {
  if (id) {
    const { memberCount } = await (
      await fetch('/api/campaigns/' + id + '/memberCount')
    ).json()
    return memberCount
  }
  return null
}

export async function getCannedResponseForCampaign(id, campaignId) {
  try {
    if (id && campaignId) {
      const response = await fetch(
        '/api/campaigns/' + campaignId + '/canned-responses/' + id
      )
      const { cannedResponse, message } = await response.json()
      if (response.status === 200) {
        return { cannedResponse, message: null }
      } else {
        return { cannedResponse: null, message: message }
      }
    }
    return { cannedResponse: null, message: 'INVALID' }
  } catch (e) {
    console.error(e)
    return { cannedResponse: null, message: 'ERROR' }
  }
}

export async function getFlowsForCampaign(id) {
  if (id) {
    const { flows } = await (
      await fetch('/api/campaigns/' + id + '/flows')
    ).json()
    return flows
  }
  return null
}

export async function getOutgoingMessageForBlast(
  id,
  decodedMessageId,
  groups,
  editScheduled
): Promise<BroadcastComposerOutgoingMessageFetchResults> {
  if (id && decodedMessageId) {
    const { outgoingMessage, isAbOutgoingMessage, childFlows, recurrenceRule } =
      await (
        await fetch(
          `/api/campaigns/${id}/outgoing-messages/${decodedMessageId}${editScheduled ? '?editScheduled=true' : ''}`
        )
      ).json()
    return {
      outgoingMessage: manipulateGroupsForBlastPage(outgoingMessage, groups),
      isAbOutgoingMessage,
      childFlows,
      recurrenceRule
    }
  }
  return {
    outgoingMessage: undefined,
    isAbOutgoingMessage: undefined,
    childFlows: undefined,
    recurrenceRule: undefined
  }
}

export function provideReadableJsonAttachment(attachment) {
  let fullAttachment
  try {
    fullAttachment = JSON.parse(attachment)
  } catch (e) {
    fullAttachment = attachment
  }
  return fullAttachment
}

function manipulateGroupsForBlastPage(
  outgoingMessage: OutgoingMessage,
  groups = []
): OutgoingMessageInterface {
  if (!outgoingMessage) return null
  const groupOptions = groups
    .map(({ id, title }) => ({
      label: title,
      value: id as string | number
    }))
    .concat([{ label: 'All', value: 'all' }])

  const newOM = { ...outgoingMessage }

  newOM.recipients = newOM.recipients || {}

  // moving this to client side handling instead of from route.
  // only set if recipients is set.
  if (newOM.recipients.campaignGroups) {
    newOM.recipients.campaignGroups = groupOptions.filter((g) =>
      newOM.recipients.campaignGroups?.includes(g.value)
    )
    if (newOM.recipients.campaignGroups.length === 0) {
      newOM.recipients.campaignGroups = [{ label: 'All', value: 'all' }]
    }
  }
  if (newOM.recipients.excludeGroups) {
    newOM.recipients.excludeGroups = groupOptions.filter((g) =>
      newOM.recipients.excludeGroups?.includes(g.value)
    )
  }

  newOM.attachment = newOM.attachment
    ? provideReadableJsonAttachment(newOM.attachment)
    : null

  return newOM
}

/** Custom Fields */
export const addCustomField = async ({
  fieldName,
  campaignId,
  customType,
  options,
  existingKey
}) => {
  const newField = {
    name: fieldName,
    type: customType,
    options,
    identifier: existingKey
  }
  const response = await fetch(`/api/campaigns/${campaignId}/custom-fields`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newField)
  })
  const result = await response.json()
  return result
}

export const updateCustomFieldOptions = async ({
  field,
  options,
  campaignId
}) => {
  return addCustomField({
    fieldName: field.label || field.value,
    campaignId,
    customType: field.type,
    options,
    existingKey: field.value
  })
}

export const deleteCustomField = async (campaignId, existingKey) => {
  const newField = {
    identifier: existingKey
  }
  const response = await fetch(`/api/campaigns/${campaignId}/custom-fields`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newField)
  })
  const result = await response.json()
  return result
}
